<template>
  <div>
    <!-- <div class="bgone">
      <div class="bgone_tit">
        <div>
          <div><h1>为企业提供一件代发服务</h1></div>
          <div>
            <p>自动订单管理，方便用户快捷操作</p>
          </div>
        </div>
      </div>
    </div> -->
    <div>
      <div class="contWidth1440 marginAuto wuhuoyuan">
        <div class="wuhuoyuan_left">
          <h1>无货源开店，低成本运营</h1>
          <div
            style="width: 550px; margin-top: 30px; font-size: 18px; color: #666"
          >
            万鲸供应链为您提供上百万SKU一件代发，您只需在选品页面进行一键选品、一键上架，我们将为您解决后续订单履约流程，零库存管理成本
          </div>
          <a-button class="tryBtns">
            <a
              style="color: #fff"
              href="https://console.wanjingai.com/user/register"
              >立即试用</a
            >
            <a-icon type="right" style="color: #fff; margin-left: 10px" />
          </a-button>
        </div>
        <div class="wuhuoyuan_right">
          <img src="../../assets/home/bgR.png" />
        </div>
      </div>
    </div>

    <div class="section-123" style="padding: 120px 0">
      <div class="container_body-2 w-container">
        <div class="div-block-557">
          <div class="w-layout-grid grid-74">
            <div class="node div-block-558">
              <img
                src="https://shoplineapp.cn/images/shopline-dropshipping-step1.webp"
                alt=""
                class="image-490"
              />
              <div class="text-block_icon-3">Step 1</div>
              <div class="text-block_icon-4">
                使用万鲸云控制台中的万鲸供应链服务，一键从万鲸供应链快速导入产品<br />
              </div>
            </div>
            <div class="node div-block-558">
              <img
                src="https://shoplineapp.cn/images/shopline-dropshipping-step2.webp"
                alt=""
                class="image-490"
              />
              <div class="text-block_icon-3">Step 2</div>
              <div class="text-block_icon-4">
                订单一旦被确认，万鲸供应链会自动将订单转发给一件代发供应商<br />
              </div>
            </div>
            <div class="node div-block-558">
              <img
                src="https://shoplineapp.cn/images/shopline-dropshipping-step3.webp"
                alt=""
                class="image-490"
              />
              <div class="text-block_icon-3">Step 3</div>
              <div class="text-block_icon-4">
                一件代发供应商收到订单后，将会为您的客户立刻安排发货<br />
              </div>
            </div>
            <div class="node div-block-558">
              <img
                src="https://shoplineapp.cn/images/shopline-dropshipping-stpe4.webp"
                alt=""
                class="image-490"
              />
              <div class="text-block_icon-3">Step 4</div>
              <div class="text-block_icon-4">
                供应商将代表您将订单直接发货给客户，因此实际上您不必管理任何库存<br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contWidth1440 marginAuto" style="padding: 120px 0">
      <div class="container_body-2 w-container">
        <div class="hero-wrap_1-2">
          <div class="node content-wrap_1-2">
            <h1>启动资金少，无库存压力</h1>
            <p>
              一件代发的商业模式意味着在收到订单之前，您不需要采购任何商品，启动成本低；保持无库存运营，减少您的现金流风险和仓储压力
            </p>
            <a-button class="tryBtns">
              <a
                style="color: #fff"
                href="https://console.wanjingai.com/user/register"
                >立即试用</a
              >
              <a-icon type="right" style="color: #fff; margin-left: 10px" />
            </a-button>
          </div>
          <div class="node image-wrap_1">
            <img src="../../assets/home/bgR6.png" class="image_body-1" />
          </div>
        </div>
      </div>
    </div>
    <div style="background: #f6f8fa">
      <div class="contWidth1440 marginAuto wuhuoyuan">
        <div class="node image-wrap_1">
          <img src="../../assets/home/bgR7.png" />
        </div>
        <div class="node content-wrap_1-2" style="width: 550px">
          <h1>结算灵活，先收钱后采购</h1>
          <p class="paragraph_body-2">
            客户在商城下单付款到您的收款账户，您再实时支付采购款进行一件代发操作；或者您提前将采购款充值到余额账户，无需进行一件代发操作，系统会自动进行划扣并安排发货。
          </p>
          <a-button class="tryBtns">
            <a
              style="color: #fff"
              href="https://console.wanjingai.com/user/register"
              >立即试用</a
            >
            <a-icon type="right" style="color: #fff; margin-left: 10px" />
          </a-button>
        </div>
      </div>
    </div>

    <div class="contWidth1440 marginAuto wuhuoyuan">
      <div class="node content-wrap_1-2" style="width: 520px">
        <h1>自动化管理一件代发订单，更多时间专注营销</h1>
        <div style="margin-top: 30px; width: 580px">
          <p>
            追踪和管理订单是运营中最耗时耗力的环节，万鲸云帮助您自动管理订单，并将其发送给供应商，您将有更多时间用于营销增收和扩展业务
          </p>
        </div>
        <a-button class="tryBtns">
          <a
            style="color: #fff"
            href="https://console.wanjingai.com/user/register"
            >立即试用</a
          >
          <a-icon type="right" style="color: #fff; margin-left: 10px" />
        </a-button>
      </div>
      <div class="node image-wrap_1">
        <img src="../../assets/home/bgR1.png" style="width: 642px" />
      </div>
    </div>

    <div style="background: #f6f8fa">
      <div class="wuhuoyuan contWidth1440 marginAuto">
        <div class="node image-wrap_1">
          <img style="width: 642px" src="/mp/jj.png" />
        </div>
        <div class="node content-wrap_1-2" style="width: 550px">
          <h1>万鲸供应链品质甄选，使您的利润最大化</h1>
          <div style="margin-top: 30px">
            <p class="paragraph_body-2">
              万鲸供应链甄选国内全品类供应商，便于您寻找可靠且优质的供应商，让您以合适的价格获得最优的商品，使您的利润最大化
            </p>
          </div>
          <a-button class="tryBtns">
            <a
              style="color: #fff"
              href="https://console.wanjingai.com/user/register"
              >立即试用</a
            >
            <a-icon type="right" style="color: #fff; margin-left: 10px" />
          </a-button>
        </div>
      </div>
    </div>

    <div class="contWidth1440 marginAuto" style="padding: 120px 0">
      <div class="">
        <div class="jiantext" style="margin-bottom: 60px">
          <h1>一件代发常见问题</h1>
        </div>
        <div class="content-warp_faq-1">
          <div class="content-warp_faq-2">
            <div class="content-wrap_5 faq" @click="fqa1 = !fqa1">
              <div
                class="paragraph_body-p1"
                style="color: #333333; font-weight: bold"
              >
                1. 一件代发是什么意思?
              </div>
              <div :class="fqa1 ? 'kkks' : 'kkk'">
                <a-icon :type="fqa1 ? 'up' : 'down'" style="font-size: 14px" />
              </div>
            </div>
            <p
              class="paragraph_body-p2 gray faq"
              style="color: #666666; line-height: 35px"
              v-show="fqa1"
            >
              一件代发是指消费者在零售商处下单，零售商省略进货的环节，通过批发商或制造商直发消费者，降低交易成本。随着数字化供应链的发展，一件代发也成为一种热门的销售模式。
            </p>
          </div>

          <div class="content-warp_faq-2" style="">
            <div class="content-wrap_5 faq" @click="fqa2 = !fqa2">
              <div class="paragraph_body-p1">2.一件代发在哪里可以设置？</div>
              <div :class="fqa2 ? 'kkks' : 'kkk'">
                <a-icon
                  :type="fqa2 ? 'up' : 'down'"
                  style="font-size: 14px"
                  class="image_faq"
                />
              </div>
            </div>
            <p class="paragraph_body-p2 gray faq" v-show="fqa2">
              您可以在后台订单管理里面进行一件代发操作，或者进入后台财务管理里面进行充值并开通自动一件代发功能。
            </p>
          </div>

          <div class="content-warp_faq-2">
            <div class="content-wrap_5 faq" @click="fqa3 = !fqa3">
              <p class="paragraph_body-p1">3. 我可以在哪里采购一件代发产品？</p>
              <div :class="fqa3 ? 'kkks' : 'kkk'">
                <a-icon
                  :type="fqa3 ? 'up' : 'down'"
                  style="font-size: 14px"
                  class="image_faq"
                />
              </div>
            </div>
            <p class="paragraph_body-p2 gray faq" v-show="fqa3">
              万鲸供应链汇聚了海量商品，所有商品都支持一件代发。商家可以在这里浏览经过审查的供应商提供的数百万种产品。您可以直接登录进行一键选品上架销售，有了订单之后可以在后台订单管理里面进行一件代发操作，我们将第一时间为您进行发货。
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <OverseaFooterBanner></OverseaFooterBanner> -->
    <HomeFooterBanner :title="titles"></HomeFooterBanner>
  </div>
</template>

<script>
import HomeFooterBanner from "@/Layouts/components/FooterBanner/footerBanner/index.vue";

// import OverseaFooterBanner from "@/Layouts/components/FooterBanner/overseaFooterBanner/index.vue";

/**
 * @author  XuHongli
 * @date  2023/3/2 15:03
 * @version 1.0
 * @description index.vue
 */
export default {
  name: "Oversea",
  components: { HomeFooterBanner },
  data() {
    return {
      fqa1: false,
      fqa2: false,
      fqa3: false,
      openStyle:
        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg)",
      closeStyle:
        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
      titles: {
        a: "万鲸供应链，满足您选品的一切需求",
        b: "全品类甄选商品帮您一件代发，开启零库存运营",
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/shopline.scss";

.xin {
  background: #f6f8fa;
  height: 88px;
  border-radius: 5px;
  position: relative;
  font-size: 21px;
  font-family: 600;
  line-height: 88px;
}
.xin::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  background: #ff6a00;
  height: 88px;
}

.bgone {
  background: url(../../assets/home/bg1.png) no-repeat;
  height: 450px;
  width: 100%;
  background-size: 100% 100%;
  &_tit {
    width: 1440px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

p {
  font-size: 18px;
  color: #333;
}
</style>
